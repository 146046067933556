<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn
                      color="primary"
                      dark
                      class="ml-auto ma-3 mr-1"
                      @click="refreshData"
                    >
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn
                      v-if="hasPermission('faqs.create')"
                      color="primary"
                      dark
                      class="ma-3 ml-0"
                      v-on="on"
                    >
                      <i class="flaticon2-plus text-white"></i>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <i class="fas fa-times custom-times" @click="closeDialog"></i>
                  <v-card-title>
                    <span v-if="editedItem.id"
                      >Edit {{ editedItem.title }}</span
                    >
                    <span v-else>Create Faq</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form
                      ref="form"
                      v-model="validationRules.valid"
                      lazy-validation
                    >
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="editedItem.question"
                            :counter="255"
                            :rules="validationRules.questionRules"
                            label="Question*"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <label>Answer*</label>
                          <editor
                              :api-key="tinyKey"
                              ref="et_editor"
                              v-model="editedItem.answer"
                              :init="editorInit"
                          />
                          <br />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="editedItem.sort_order"
                            :counter="5"
                            :rules="validationRules.sortRules"
                            @keypress="isNumber($event)"
                            label="Sort order"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            clearable
                            v-model="editedItem.enabled"
                            :items="ques"
                            :rules="validationRules.selectedRules"
                            item-text="title"
                            item-value="value"
                            label="Enabled*"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog"
                      >Cancel</v-btn
                    >
                    <v-btn
                      :disabled="!validationRules.valid"
                      color="blue darken-1"
                      text
                      @click="saveItem(editedItem)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div
                      v-if="errors != undefined && errors.length >= 0"
                      role="alert"
                      v-bind:class="{ show: errors.length }"
                      class="alert fade alert-danger"
                    >
                      <div
                        class="alert-text"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0" />
              <v-skeleton-loader
                  :loading="loading"
                  v-if="isLoaded"
                  type="table"
              >
              </v-skeleton-loader>
              <v-card-title>
                Faqs ({{ totalRecords }})
                <v-spacer></v-spacer>
                <v-text-field
                  @keydown.enter="searchQueryData"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="pagination"
                :server-items-length="totalRecords"
                :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                :loading="loading"
                v-show="!isLoaded"
                mobile-breakpoint="800"
                class="elevation-0"
              >
                <template v-slot:item.question="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.question.substring(0, 100) + '...'"></span>
                  </div>
                </template>
                <template v-slot:item.answer="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.answer.substring(0, 100) + '...'"></span>
                  </div>
                </template>
                <template v-slot:item.enabled="{ item }">
                  <div class="text-truncate">
                    <p v-if="item.enabled == 0">No</p>
                    <p v-if="item.enabled == 1">Yes</p>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="hasPermission('faqs.edit')"
                          color="primary"
                          @click="showEditDialog(item)"
                          dark
                          class="mr-2"
                          v-on="on"
                        >
                          <v-icon dark small>fas fa-edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="hasPermission('faqs.delete')"
                          color="red"
                          dark
                          @click="deleteItem(item)"
                          class="mr-2"
                          v-on="on"
                        >
                          <v-icon dark small>fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FAQS_LIST,
  CREATE_FAQ,
  UPDATE_FAQ,
  CLEAR_FAQ_ERRORS,
  DELETE_FAQ,
  GET_FAQ_DETAILS
} from "@/core/services/store/faqs.module";
import { SET_CONFIRMATION } from "@/core/services/store/confirmation.module";

export default {
  name: "faqs",
  data() {
    return {
      editorInit: {
        branding: false,
        height: 500,
        menubar: false,
        automatic_uploads: false,
        plugins: [
          "advlist autolink lists code link charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code wordcount fullscreen"
        ],
        toolbar:
            "undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | code | link  | fullscreen"
      },
      headers: [
        {
          text: "Question",
          value: "question",
          name: "question",
          filterable: true,
          sortable: false
        },
        {
          text: "Answer",
          value: "answer",
          name: "answer",
          filterable: true,
          sortable: false
        },
        {
          text: "Sort order",
          value: "sort_order",
          name: "sort_order",
          filterable: false,
          sortable: false
        },
        {
          text: "Enabled",
          value: "enabled",
          name: "enabled",
          filterable: true,
          sortable: false
        },
        {
          text: "Actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false
        }
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 0,
        totalItems: 0
      },
      totalRecords: 0,
      rowsPerPageItems: [10, 15, 20, 30, 40, 50],
      columns: [],
      validationRules: {
        valid: true,
        questionRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 255) ||
            "Must be less than or equal to 255 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        answerRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 1000) ||
            "Must be less than or equal to 1000 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        sortRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 5) ||
            "Must be less than or equal to 5 characters",
          v => (v && v.length >= 1) || "Must be at least 1 characters"
        ],
        selectedRules: [v => !!v || "This field is required"]
      },
      items: [],
      dialog: false,
      rDialog: false,
      editedItem: {},
      ques: [
        { value: "0", title: "No" },
        { value: "1", title: "Yes" }
      ]
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_FAQ_ERRORS);
      if (!val) this.editedItem = {};
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
        "&columns[" +
          i +
          "][data]=" +
          this.headers[i].value +
          "&columns[" +
          i +
          "][name]=" +
          this.headers[i].name +
          "&columns[" +
          i +
          "][searchable]=" +
          this.headers[i].filterable +
          "&columns[" +
          i +
          "][orderable]=" +
          this.headers[i].sortable +
          "&columns[" +
          i +
          "][search][value]=" +
          "&columns[" +
          i +
          "][search][regex]=" +
          false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Content Management", route: "faqs" },
      { title: "Faqs" }
    ]);
  },
  methods: {
    searchQueryData: function(e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise(resolve => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fecth per page
        params.start =
          params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
          .map(key => {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
          })
          .join("&");
        query +=
          "&search[value]=" +
          params.query +
          "&search[regex]=" +
          false +
          "&order[0][column]=" +
          params.sortBy +
          "&order[0][dir]=" +
          (params.descending ? "desc" : "asc");
        this.$store
          .dispatch(FAQS_LIST, { q: query, columns: this.columns })
          .then(res => {
            this.draw++;
            let items = res.records.data;
            let total = res.records.recordsFiltered;
            this.loading = !this.loading;
            this.isLoaded = !this.isLoaded;
            resolve({
              items,
              total
            });
          });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_FAQ_DETAILS, item.id).then(data => {
        this.editedItem = data.records || {};
        this.dialog = !this.dialog;
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
        let method = CREATE_FAQ;
        let id = item.id;
        let data = {
          data: item
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_FAQ;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_FAQ_ERRORS);
        // save the record
        this.$store.dispatch(method, { slug: id, data: data.data }).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {};
          this.dialog = !this.dialog;
        });
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete?",
        clr: "red",
        callback: function() {
          let id = item.id;
          this.$store
            .dispatch(DELETE_FAQ, {
              faq_id: id
            })
            .then(() => {
              that.loadData().then(response => {
                that.items = response.items;
                that.totalRecords = response.total;
              });
            });
        }
      });
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.faqs.errors
    })
  }
};
</script>
